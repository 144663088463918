import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2004-2007",
      title: "Bachelor of Computer Applications",
      place: "Pune University",
      desc: "Bachelor of Compure Applications with 72%",
    },
    {
      yearRange: "2003-2004",
      title: "Higher Secondary (12th)",
      place: "Laxmarnrao Apte School, Pune, Maharashtra, India",
      desc: "Higher Secondary with 62%",
    },
    {
      yearRange: "2001-2002",
      title: "Seondary (10th)",
      place: "Abhinava Vidyala, Pune Maharashtra, India",
      desc: "10th class with 77 %.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "Oct 2020 - till date (30 months)",
      title: "Frontend developer - Angular",
      place: "EPAM Systems, Pune, https://www.epam.com/",
      desc: "Working as Sr. Frontend developer in Angular",
    },
    {
      yearRange: "Sep 2019 - Aug 2020 (11 months)",
      title: "Frontend Developer, Angular",
      place: "Moentek Pvt Ltd, Pune now Convirza, https://www.convirza.com/",
      desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "Dec 2018 - Aug 2019 (9 months)",
      title: "Fullstack Developer",
      place: "Infosys, Pune, https://www.infosys.com/",
      desc: "Fullstack Developer (Angular, Node.js)",
    },
    {
      yearRange: "Mar 2011 - Nov 2018 (84 months)",
      title: "Developer",
      place: "Persistent systems, Pune, https://www.persistent.com/",
      desc: "Fullstack Developer (Angular, Node.js)",
    },
    {
      yearRange: "Nov 2007 - Feb 2011 (36 months)",
      title: "Developer",
      place: "Patni Computer Systems, Pune, now Capgemini, https://www.capgemini.com/",
      desc: "Fullstack Developer (Angular, Node.js)",
    }
  ];

  const skills = [
    {
      name: "Angular",
      percent: 90,
    },
    {
      name: "HTML/CSS",
      percent: 90,
    },
    {
      name: "JavaScript",
      percent: 90,
    },
    {
      name: "React JS",
      percent: 40,
    },
    {
      name: "Node.js",
      percent: 80,
    },
    {
      name: "UI / UX",
      percent: 60,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
